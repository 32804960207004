import React from "react"
import Layout from "../../../containers/layout"
import Common from "./common"
import {
  getMonthChartByDate as getDayChart,
  lookupChart,
} from "../../../cm-lib/QiMen"
import { parseDateString } from "../../../helpers/datetime"
import { getQueryString } from "../../../helpers"

const Home = () => {
  const qs = getQueryString()
  const useDate = parseDateString(qs.dt)
  const chartMeta = getDayChart(useDate)
  const chart = lookupChart(chartMeta.structure, chartMeta.pillar)
  return (
    <Layout>
      <Common
        chart={chart}
        initialDate={useDate}
        heading="Qi Men Dun Jia Day Chart"
        chartType="Day Chart"
      />
    </Layout>
  )
}

export default Home
